<!-- xuwencheng  2022年6年29日18:00  这是我的wenzhangbianji -->
<!-- 编辑 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destory-on-close="true"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
  >
    <el-form ref="form" label-width="200px" :model="form" :rules="rules">
      <el-form-item label="直播名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入直播名称" />
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <el-input v-model="form.img" style="display: none" />
        <el-upload
          v-model="form.img"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :before-upload="beforeAvatarUpload"
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          :on-success="handleAvatarSuccess"
          :show-file-list="false"
        >
          <img v-if="imageUrl" class="avatar" :src="imageUrl" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div style="font-size: 12px;">再次点击图片,可以修改图片</div>
      </el-form-item>
      <el-form-item label="类型" prop="isZhibo">
        <el-radio-group size="small" v-model="form.isZhibo">
          <el-radio :label="0">直播</el-radio>
          <el-radio :label="1">重播</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="会议" prop="plateId">
        <el-select v-model="form.plateId" size="small" placeholder="请选择">
          <el-option
            v-for="item in plateList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="直播路径" prop="url">
        <el-input v-model="form.url" placeholder="请输入直播路径" />
      </el-form-item>
      <!-- <el-form-item label="简介" prop="czsTitle">
        <el-input
          type="textarea"
          :rows="2"
          v-model="form.czsTitle"
          placeholder="请输入简介"
        />
      </el-form-item> -->
    </el-form>
    <el-button class="baocun" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </el-dialog>

  <!-- 增加 -->
</template>
          
          <script>
/* eslint-disable*/
import { updateVideo, addvideo } from "@/api/zhanyingshang";
import util from "@/common/utils/util.js";
import { getToken } from "@/common/utils/auth";
import { getPlateList, getPlateListWithGroup } from "@/api/plate";

//import { number } from 'echarts'
export default {
  name: "search",

  data() {
    return {
      selectedOptions: [],
      token: "",
      imageUrl: "",
      plateList: [],
      value: true,
      value1: true,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      options1: [],
      options2: [],
      options3: [],
      valueone: "",
      valuetwo: "",
      form: {},
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入名称" }],
        czsTitle: [{ required: true, trigger: "blur", message: "请输入简介" }],
        url: [{ required: true, trigger: "blur", message: "请输入路径" }],
        isZhibo: [{ required: true, trigger: "change", message: "请选择类型" }],
        img: [{ required: true, trigger: "change", message: "请添加封面图片" }],
        plateId: [
          { required: true, trigger: "change", message: "请选择关联会议" },
        ],
      },
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.img = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return true;
    },

    showEdit(row) {
      let t = getToken();
      this.token = t;
      this.init();
      if (!row) {
        this.title = "添加";
        this.imageUrl = "";
      } else {
        this.title = "编辑";
        this.form = Object.assign({}, row);
        console.log(row, "ww");
        this.imageUrl = row.img;
      }

      this.dialogFormVisible = true;
    },
    init() {
      getPlateList().then((res) => {
        if (res.code) {
          this.plateList = res.data;
          // this.plateList.unshift({title: "请选择会议", id:''})
        }
      });
    },
    close() {
      let row = {};
      this.form = Object.assign({}, row);
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = addvideo;
          let title = "添加";

          if (this.form.id) {
            func = updateVideo;
            title = "修改";
            loadingText = "修改中...";
          }
          func(this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.dialogFormVisible = false;
                  this.close();
                  this.$emit("fetch-data");
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
          <style>
/* .huakuai {
              position: relative;
              left: 96%;
            } */
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
.baocun {
  width: 100%;
  height: 100%;
}
</style>
          